<template>
    <el-dialog :visible.sync="getShowDialog" title="Toggle Open Sender ID" width="35%" @closed="$emit('cancelModal')">
        <h6>Warning:</h6>
        <p v-if="modalPayload.data && modalPayload.data.open_sender_is_active == 0">This will <span
                class="allow">allow</span> <strong>{{
                    modalPayload.data.name }} </strong>to create sender IDs with
            automatic approval
        </p>
        <p v-if="modalPayload.data && modalPayload.data.open_sender_is_active == 1">This will <span
                class="prevent">prevent</span> <strong>{{
                    modalPayload.data.name }} </strong>to create sender IDs with
            automatic approval
        </p>
        <span slot="footer" class="dialog-footer" v-if="modalPayload.data">
            <el-button type="primary" @click="$emit('cancelModal')">Cancel</el-button>
            <el-button :loading="buttonLoading" type="danger" @click="submitChange"
                v-if="modalPayload.data.open_sender_is_active == 0">Approve</el-button>
            <el-button :loading="buttonLoading" type="danger" @click="preventOpenSenderID" v-else>Deny</el-button>
        </span>

    </el-dialog>
</template>
<script>
export default {
    name: "toggleOpenSenderID",
    data() {
        return {
            payload: {
                user_id: "",
            },
            rules: {
                type: [{
                    required: true,
                    message: "Please select a user type",
                    trigger: "change"
                }]
            },
            buttonLoading: false
        };
    },
    props: {
        modalPayload: {
            type: Object,
            default: () => {
                return {

                };
            },
            required: true
        },
        showOpenSenderDialog: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getShowDialog: {
            set() {
                this.$emit('closed');
            },
            get() {
                return this.showOpenSenderDialog;
            }
        },
    },
    methods: {
        submitChange() {
            this.buttonLoading = true;
            this.payload.user_id = this.modalPayload.data.id;
            this.$store.dispatch('admin/users/allowOpenSenderID', this.payload).then(() => {
                this.$message({
                    type: 'success',
                    message: `${this.modalPayload?.data?.name} is now allowed to create sender IDs with automatic approval.`
                });
                this.$emit('cancelModal');
                this.$emit('refreshTable')
            }).catch((error) => {
                this.$message({
                    type: 'error',
                    message: "There was an error toggling the open sender ID. Please try again later."
                });
            }).finally(() => {
                this.buttonLoading = false;
            });
        },
        preventOpenSenderID() {
            this.buttonLoading = true;
            this.payload.user_id = this.modalPayload.data.id;
            this.$store.dispatch('admin/users/denyOpenSenderID', this.payload).then(() => {
                this.$message({
                    type: 'error',
                    message: `${this.modalPayload.data?.name} can no longer create sender IDs with automatic approval.`
                });
                this.$emit('cancelModal');
                this.$emit('refreshTable')
            }).catch((error) => {
                this.$message({
                    type: 'error',
                    message: "There was an error toggling the open sender ID. Please try again later."
                });
            }).finally(() => {
                this.buttonLoading = false;
            });
        }
    }
}
</script>
<style scoped>
.select-user-type {
    width: 100%;
}

.select-user-type * {
    width: 100%;
}

p {
    width: 100%;
    white-space: break-spaces;
}

.allow {
    color: #03bd03;
}

.prevent {
    color: #ff0000;
}
</style>