<template>
  <el-dialog v-if="modalPayload.data" :visible.sync="getShowSubsciptionTypeModal" title="Toggle Subscription Type" width="35%"
    @closed="$emit('cancelModal')">
    <h5>Current User Details</h5>

    <div  style="display: flex; flex-direction: column; margin-bottom: 1.5rem">
      <strong>Name: <code>{{ modalPayload.data.name }}</code></strong>
      <strong>Email: <code>{{ modalPayload.data.email }}</code></strong>
      <strong>Subscription Type: <code>{{ old_profileType || 'None' }}</code></strong>
    </div>
    <div class="select-subscription-type">
      <el-form :model="payload" :rules="rules" ref="selectTypeForm">
        <el-form-item label="Change Subscription Type" prop="status">
          <el-select v-model="payload.status">
            <el-option label="Gold" value="1"></el-option>
            <el-option label="Silver" value="2"></el-option>
            <el-option label="Bronze" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="$emit('cancelModal')">Cancel</el-button>
      <el-button type="danger" @click="validateUser">Confirm</el-button>
    </span>
    <el-dialog title="Confirmation" :visible.sync="isConfirmationDialogVisible" :append-to-body="true">
      <span>Are you sure you want to change the user subscription?</span>
      <p v-if="checkModalPayloadStatus">
        Converting <span class="name">{{ getPayloadName }}</span> from
        <span class="old">{{ old_profileType || 'None' }}</span> to
        <span class="new">{{ new_profileType }}</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isConfirmationDialogVisible = false">Cancel</el-button>
        <el-button type="danger" @click="submitChange" :loading="buttonLoading">Confirm</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import Storage from "../../../services/storage"
export default {
  name: "toggleSubscriptionType",
  data() {
    return {
      isConfirmationDialogVisible: false,
      payload: {
        created_by: "",
        user_id: "",
        status: "",
        previousType: "",
      },
      rules: {
        status: [
          {
            required: true,
            message: "Please select a subscription type",
            trigger: "change",
          },
        ],
      },
      buttonLoading: false,
    };
  },
  props: {
    modalPayload: {
      status: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    showSubsciptionTypeModal: {
      status: Boolean,
      default: false,
    },
  },
  computed: {
    getShowSubsciptionTypeModal: {
      set() {
        this.$emit("closed");
      },
      get() {
        return this.showSubsciptionTypeModal;
      },
    },

    old_profileType() {
      if (this.modalPayload.data?.status == 1) {
        return "Gold";
      } else if (this.modalPayload.data?.status == 2) {
        return "Silver";
      } else if (this.modalPayload.data?.status == 3) {
        return "Bronze";
      }



    },
    new_profileType() {
      if (this.payload.status == "1") {
        return "Gold";
      } else if (this.payload.status == "2") {
        return "Silver";
      } else if (this.payload.status == "3") {
        return "Bronze"
      }
    },
    checkModalPayloadStatus() {
      return this.modalPayload?.data?.status != this.payload.status;
    },
    getPayloadName() {
      return this.modalPayload?.data?.name || "User";
    },
  },

  methods: {
    validateUser() {
      this.$refs.selectTypeForm.validate((valid) => {
        if (valid) {
          let storage = new Storage()
          let adminID = storage.getSavedState('auth.admin').user_id
          this.payload = {
            user_id: this.modalPayload.data.id,
            created_by: adminID,
            status: this.payload.status,
            previousType: this.modalPayload.data.is_profile_complete,
          };
          this.isConfirmationDialogVisible = true;
        } else {
          return false;
        }
      });
    },
    submitChange() {
      this.buttonLoading = true;
      this.$store
        .dispatch("admin/users/storeUserSegments", this.payload)
        .then(() => {
          this.$message({
            type: "success",
            message: "User Subscription changed successfully!",
          });
          this.isConfirmationDialogVisible = false;
          this.$emit("cancelModal");
          this.$emit("refreshTable");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message:
              "There was an error changing the user Subscription. Please try again later.",
          });
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.select-user-type {
  width: 100%;
}

.select-user-type * {
  width: 100%;
}

.old {
  color: red;
}

.new {
  color: green;
}

.name {
  color: blue;
}

.select-subscription-type * {
  width: 100%;
}
</style>
  