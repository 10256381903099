<template>
  <el-dialog
    :visible.sync="getShowDialog"
    title="Toggle User Type"
    width="35%"
    @closed="$emit('cancelModal')"
  >
    <h5>Current User Details</h5>
   

    <div
      v-if="modalPayload.data"
      style="display: flex; flex-direction: column; margin-bottom: 1.5rem"
    >
      <strong
        >Name: <code>{{ modalPayload.data.name }}</code></strong
      >
      <strong
        >Email: <code>{{ modalPayload.data.email }}</code></strong
      >
      <strong
        >Profile Type: <code>{{ old_profileType }}</code></strong
      >
    </div>
    <div class="select-user-type">
      <el-form :model="payload" :rules="rules" ref="selectTypeForm">
        <el-form-item label="Change User Type" prop="type">
          <el-select v-model="payload.type">
            <el-option label="Individual" value="2"></el-option>
            <el-option label="Corporate" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="select-subscription-type" 
      <el-form :model="payload" :rules="rules" ref="selectTypeForm">
        <el-form-item label="Change Subscription Type" prop="type">
          <el-select v-model="payload.type">
            <el-option label="Gold" value="1"></el-option>
            <el-option label="Silver" value="2"></el-option>
            <el-option label="Bronze" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div> -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="$emit('cancelModal')">Cancel</el-button>
      <el-button type="danger" @click="validateUser">Confirm</el-button>
    </span>
    <el-dialog
      title="Confirmation"
      :visible.sync="isConfirmationDialogVisible"
      :append-to-body="true"
    >
      <span>Are you sure you want to change the user type?</span>
      <p v-if="modalPayload.data">
        Converting <span class="name">{{ modalPayload.data.name }}</span> from
        <span class="old">{{ old_profileType }}</span> to
        <span class="new">{{ new_profileType }}</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isConfirmationDialogVisible = false"
          >Cancel</el-button
        >
        <el-button type="danger" @click="submitChange" :loading="buttonLoading"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
export default {
  name: "toggleUserType",
  data() {
    return {
      isConfirmationDialogVisible: false,
      payload: {
        user_id: "",
        type: "",
        previousType: "",
      },
      rules: {
        type: [
          {
            required: true,
            message: "Please select a user type",
            trigger: "change",
          },
        ],
      },
      buttonLoading: false,
    };
  },
  props: {
    modalPayload: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getShowDialog: {
      set() {
        this.$emit("closed");
      },
      get() {
        return this.showDialog;
      },
    },
   
    old_profileType() {
    
        if (this.modalPayload.data) {
          return this.modalPayload.data.is_profile_complete == 1 ? "INDIVIDUAL": "CORPORATE";
        } 
        return this.modalPayload.data.is_profile_complete == 1 ? "INDIVIDUAL": "CORPORATE";  
          
    },
    new_profileType() {

          return this.payload.type == 2 ?"INDIVIDUAL" : "CORPORATE";
        }
    
   
  },

  methods: {
    validateUser() {
      this.$refs.selectTypeForm.validate((valid) => {
        if (valid) {
          this.payload = {
            user_id: this.modalPayload.data.id,
            type: this.payload.type,
            previousType: this.modalPayload.data.is_profile_complete,
          };
          this.isConfirmationDialogVisible = true;
        } else {
          return false;
        }
      });
    },
    submitChange() {
      this.buttonLoading = true;
      //if payload.type ==3 use another endpoint
      this.$store
        .dispatch("admin/users/toggleUserCorporateIndividual", this.payload)
        .then(() => {
          this.$message({
            type: "success",
            message: "User type changed successfully!",
          });
          this.isConfirmationDialogVisible = false;
          this.$emit("cancelModal");
          this.$emit("refreshTable");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message:
              "There was an error changing the user type. Please try again later.",
          });
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.select-user-type {
  width: 100%;
}

.select-user-type * {
  width: 100%;
}

.old {
  color: red;
}

.new {
  color: green;
}

.name {
  color: blue;
}

.select-subscription-type * {
  width: 100%;
}
</style>
