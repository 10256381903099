<template>
  <div class="sender-id-table-action-dialog">
    <header>
      <h5 style="color: #f7921c">Bulk Edit Users</h5>
    </header>
    <div class="top-section">
      <h5>Are you sure you want to perform this action?</h5>
      <p>
        You are about to perform this action on
        <span class="bold">{{ bulkSelectedData.length }}</span> user(s).
      </p>
    </div>
    <div class="action-container">
      <el-button
        type="success"
        @click="tagBulk"
        :loading="tagLoading"
        v-if="
          !['fraudulent', 'inactive'].some((item) => $route.name.includes(item))
        "
        >Tag</el-button
      >
      <el-button
        type="warning"
        @click="deactivateBulk"
        :loading="deactivateLoading"
        >Deactivate</el-button
      >
      <el-button type="danger" @click="deleteBulk" :loading="deleteLoading"
        >Delete</el-button
      >
    </div>
    <div class="cancel-selection">
      <el-button type="danger" @click="$emit('update:visible', false)"
        >Cancel</el-button
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      bulkSelectedData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        tagLoading: false,
        deactivateLoading: false,
        deleteLoading: false,
        selectedUser: {
          key: "",
          user_id: [],
        },
        tableSelectUsers: [],
        submitData: new FormData(),
      };
    },
    computed: {
      selectedIDs() {
        return this.bulkSelectedData.map((item) => item.id);
      },
    },
    methods: {
      async tagBulk() {
        try {
          this.tagLoading = true;
          const formData = new FormData();
          formData.append("user_id[]", this.selectedIDs);
          await this.$store.dispatch("admin/users/bulkTagUsers", formData);
          this.$message.success({
            message: "Users tagged successfully",
            showClose: true,
          });
          this.$emit("update:visible", {
            success: true,
            ids: formData.getAll("user_id[]"),
          });
        } catch (error) {
          this.$message.error({
            message: error.response.data.message,
            showClose: true,
          });
        } finally {
          this.tagLoading = false;
        }
      },
      async deactivateBulk() {
        try {
          this.tagLoading = true;
          const formData = new FormData();
          formData.append("user_id[]", this.selectedIDs);
          await this.$store.dispatch("admin/users/bulkDeactiveUsers", formData);
          this.$message.success({
            message: "Users deactivated successfully",
            showClose: true,
          });
          this.$emit("update:visible", {
            success: true,
            ids: formData.getAll("user_id[]"),
          });
        } catch (error) {
          this.$message.error({
            message: error.response.data.message,
            showClose: true,
          });
        } finally {
          this.tagLoading = false;
        }
      },
      async deleteBulk() {
        try {
          this.tagLoading = true;
          const formData = new FormData();
          formData.append("user_id[]", this.selectedIDs);
          await this.$store.dispatch("admin/users/bulkDeleteUsers", formData);
          this.$message.success({
            message: "Users deleted successfully",
            showClose: true,
          });
          this.$emit("update:visible", {
            success: true,
            ids: formData.getAll("user_id[]"),
          });
        } catch (error) {
          this.$message.error({
            message: error.response.data.message,
            showClose: true,
          });
        } finally {
          this.tagLoading = false;
        }
      },
    },
  };
</script>

<style scoped>
  .sender-id-table-action-dialog {
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.481);
    width: 40rem;
    max-width: 100%;
    background-color: #fff;
    padding: 2rem;
    z-index: 2000;
    position: absolute;
    top: 56%;
    isolation: isolate;
    left: 45%;
  }
  .action-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel-selection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .bold {
    font-weight: bold;
    color: #f56c6c;
  }

  .top-section {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  header {
    margin-bottom: 2rem;
  }
</style>
